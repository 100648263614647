<template>
  <div class="flex flex-no-wrap bg-untitled-gray-50">
    <div class="w-full md:px-6 px-2">
      <div class="md:flex md:flex-wrap relative">
        <div
          class="py-10 md:w-full lg:w-2/3 w-full md:pr-6 sm:border-r border-untitled-gray-300"
        >
          <div class="flex flex-wrap">
            <div class="w-full">
              <ul :class="`flex mb-0 list-none flex-wrap pt-3 pb-4 ${selectedThemeLayout === 'default' ? 'flex-row' : 'flex-row-reverse'}`">
                <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                  <a
                    class="text-xs font-normal uppercase px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer hover:bg-untitled-gray-200 hover:text-untitled-gray-800"
                    v-on:click="toggleTabs(1)"
                    v-bind:class="{
                      'text-untitled-gray-600 bg-white': openTab !== 1,
                      'text-untitled-gray-800 bg-untitled-gray-200':
                        openTab === 1,
                    }"
                  >
                    <span id="teleport_course_label">
                      {{ label }}
                    </span>
                  </a>
                </li>
                <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                  <a
                    class="text-xs font-normal uppercase px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer hover:bg-untitled-gray-200 hover:text-untitled-gray-800"
                    v-on:click="toggleTabs(2)"
                    v-bind:class="{
                      'text-untitled-gray-600 bg-white': openTab !== 2,
                      'text-untitled-gray-800 bg-untitled-gray-200':
                        openTab === 2,
                    }"
                  >
                    {{ userName }} Learning Path & Playlists
                  </a>
                </li>
              </ul>
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
              >
                <div class="px-4 py-5 flex-auto">
                  <div class="tab-content tab-space">
                    <div v-if="openTab === 1">
                      <curriculum-selector v-if="false"></curriculum-selector>
                      <Suspense>
                        <course-section></course-section>
                        <template #fallback>
                          <div class="text-center">
                            <LoadingState />
                          </div>
                        </template>
                      </Suspense>
                    </div>
                    <div v-if="openTab === 2">
                      <learning-path></learning-path>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <right-section></right-section>
      </div>
    </div>
    <EventDialog/>
  </div>
</template>
<script setup>
import CourseSection from "../components/home/CourseSection.vue";
import LearningPath from "../components/home/learningpath/LearningPath.vue";
import RightSection from "../components/home/RightSection.vue";
import LoadingState from "@/components/partials/LoadingState";
import CurriculumSelector from "@/components/home/curriculum/CurriculumSelector";
import { ref, computed,onMounted } from "vue";
import { useAuthStore } from "@/stores/auth.store";
import { useEnrolledCourseStore } from "@/stores/enrolledCourseStore";
import EventDialog from '@/components/events/EventDialog'

const authUser = useAuthStore();
const openTab = ref(1);
const userName = computed(() => authUser.fullName);
const enrolleCourses = useEnrolledCourseStore();
const label = computed(() => enrolleCourses.label);
const selectedThemeLayout = computed(() => authUser.selectedThemeLayout);

const toggleTabs = (tabNumber) => {
  openTab.value = tabNumber;
};

onMounted(() => {
  if(selectedThemeLayout.value === 'default') {
    openTab.value = 1;
  } else {
    openTab.value = 2;
  }
})
</script>
