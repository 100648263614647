<template>
  <GlobalDialog
    :titleCenter="false"
    dialogDefaultSize="45%"
    v-model="showDialog"
    title="Completed Courses"
    :showActionButton="false"
    forceClose
  >
    <template #content>
      <div
        class="relative bg-white rounded-lg px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full sm:p-6"
      >
        <div class="flex mt-3 flex-col md:flex-row lg:flex-row justify-between">
          <div class="text-base w-auto lg:w-[200px] md:w-[190px]">
            <ECombobox
              v-model="sortBy"
              name="sorts"
              :options="sorts"
              option-name="name"
              value-key="value"
              placeholder="Sort By"
              :multiple="false"
              :filterable="false"
              :clearable="false"
            />
          </div>
        </div>
        <LoadingState v-if="loading" class="mx-auto text-center" />
        <div class="" v-else>
          <ul
            v-if="filteredCourseList.length > 0"
            :style="
              filteredCourseList.length < 4 ? 'height:auto' : 'height: 600px;'
            "
            class="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 overflow-auto"
          >
            <li
              class="grid py-6 px-4 shadow-md m-2"
              v-for="course in filteredCourseList"
              :key="course.id"
            >
              <div class="flex-shrink-0" v-if="course">
                <div class="cursor-pointer" @click="openCourse(course)">
                  <img
                    v-if="course.thumbnail"
                    :src="course.thumbnail"
                    class="w-full h-auto min-h-[150px] max-h-[250px]"
                  />
                  <img
                    v-else
                    src="https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg"
                    class="w-full h-auto"
                  />
                </div>
              </div>

              <div class="mt-2 flex flex-1 flex-col">
                <div>
                  <div class="flex justify-between">
                    <h4 class="text-sm">
                      <a
                        href="#"
                        class="font-medium text-untitled-gray-700 hover:text-untitled-gray-800"
                        >{{ course.name }}</a
                      >
                    </h4>
                    <p class="ml-4 text-sm font-medium text-untitled-gray-900">
                      {{ course.completed_unit_count }}/{{ course.unit_count }}
                      Units
                    </p>
                  </div>
                </div>

                <div class="grid">
                  <div class="w-full mx-auto">
                    <div
                      class="flex justify-between pt-5"
                      v-if="course.is_certified"
                    >
                      <p class="text-xs text-untitled-gray-500 font-bold">
                        <v-button
                          v-if="course.is_certified || course.certificate"
                          @click="
                            downloadCertificate(course.certificate.id, 'course')
                          "
                          type="button"
                          class="font-medium text-xs"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-3 h-3 mr-1"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                            />
                          </svg>

                          Certificate
                        </v-button>
                      </p>
                      <p class="text-xs text-right" v-if="course.is_certified">
                        Certifcation Date: <br />
                        {{ formatDate(course.certified_at, "MM/DD/YYYY") }}
                      </p>
                    </div>

                    <div class="flex justify-between pt-5" v-else>
                      <p class="text-xs text-untitled-gray-500 font-bold">
                        <button
                          @click="openCourse(course)"
                          type="button"
                          class="mr-4 inline-flex items-center px-3 py-2 border border-transparent text-xs leading-4 font-medium rounded-md shadow-sm text-untitled-gray-600 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-3 h-3 mr-1"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                            />
                          </svg>
                          {{ props.isLearningPath ? 'Open Playlist' : 'Open Course' }}
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div v-else class="text-center py-6">
            <p class="text-untitled-gray-500">No course found</p>
          </div>
        </div>
      </div>
    </template>
  </GlobalDialog>
</template>

<script setup>
import ECombobox from "@/components/element-components/ECombobox";
import { ref, inject, computed, defineEmits, watch, defineProps } from "vue";
import { useRouter } from "vue-router";
import LoadingState from "@/components/partials/LoadingState.vue";
import { useAuthStore } from "@/stores";
import { VButton } from "revdojo-vue-components";
import Bugsnag from "@bugsnag/js";
import formatDate from "@/composable/formatDate";
import GlobalDialog from "@/components/layout/GlobalDialog.vue";

const courseList = ref([]);
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
    default: false,
  },
  isLearningPath: {
    type: Boolean,
    required: true,
    default: false,
  },
});
const emit = defineEmits(["close", "update:modelValue"]);
const axios = inject("axios");
const router = useRouter();
const auth = useAuthStore();
const loading = ref(false);
const showDialog = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit("update:modelValue", newValue);
  },
});

const sortBy = ref(null);
const sorts = ref([
  {
    name: "A-Z",
    value: "a-z",
  },
  {
    name: "Z-A",
    value: "z-a",
  },
  {
    name: "Highest to Lowest",
    value: "htl",
  },
  {
    name: "Lowest to Highest",
    value: "lth",
  },
]);

watch(sortBy, (val) => {
  changeSort(val);
});

watch(showDialog, (value) => {
  if (value) {
    fetchCompletedCourses();
  }
});

function changeSort(sortBy) {
  courseList.value.sort((a, b) => {
    switch (sortBy) {
      case "a-z":
        return a.name.localeCompare(b.name);
      case "z-a":
        return b.name.localeCompare(a.name);
      case "htl":
        return b.completed_unit_count - a.completed_unit_count;
      case "lth":
        return a.completed_unit_count - b.completed_unit_count;
    }
  });
}

const filteredCourseList = computed(() => {
  return courseList.value;
});

const sendCertificate = async (id, type) => {
  await axios
    .post(`/api/download/certificate`, {
      userId: auth.user?.id,
      id: id,
      type: type,
    })
    .then((response) => {
      window.open(response.data, "_blank");
    })
    .catch((error) => {
      Bugsnag.notify(error);
    });
};

function downloadCertificate(id, type) {
  sendCertificate(id, type);
}

function openCourse(course) {
  if (props.isLearningPath) {
    router.push(`/units/${course.unit.id}/${course.id}`);
  } else {
    router.push(`/units/${course.unit.id}`);
  }
}

const fetchCompletedCourses = async () => {
  loading.value = true;
  await axios
    .get(`/api/v3/user/my-progress${props.isLearningPath ? '/learning-paths-completed' : '/courses-completed'}`)
    .then((response) => {
      courseList.value = response.data;
      loading.value = false;
    })
    .catch((error) => {
      Bugsnag.notify(error);
      loading.value = false;
    });
};
</script>
<style>
iframe {
  width: 100%;
  height: inherit;
}
</style>
